@import url('./orders.css');
@import url('./blogs.css');
@import url('./testimonials.css');
@import url('./countries.css');
@import url('./footerContent.css');

/* Custom Class For all the inputs in Page  */
.input-style {
  font: inherit;
  letter-spacing: inherit;
  color: currentColor;
  padding: 4px 0 5px;
  border: 0;
  border: 1px solid #909ca7;
  background: none;
  height: 3.5em;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  display: block;
  min-width: 0;
  width: 95%;
  -webkit-animation-name: mui-auto-fill-cancel;
  animation-name: mui-auto-fill-cancel;
  -webkit-animation-duration: 10ms;
  animation-duration: 10ms;
  padding: 16.5px 14px;
  border-radius: 8px;
  margin-top: 10px !important;
}
/* Package Text Area */
.pckg-text-area {
  padding: 10.5px 14px !important;
}
/* For RTE */
.tox-tinymce {
  border-radius: 5px !important;
  border: 1px solid #909ca7 !important;
}

/* YUP Error Text Color */
.error-color {
  color: red;
  margin-bottom: 5px;
  margin-top: 5px;
}
/* Form Spacing */
.spacing-form-title {
  padding-top: 10px;
}
/*  */
.text-danger {
  color: red;
}
.package-textarea {
  height: 100px;
}
