.blog-post-image {
  width: 95%;
}
/* Blog helperText Style */
.helper-text-style {
  font-size: 15px;
  margin-left: 0;
  padding-top: 3px;
}
/* Image Blog Table */
/* .blog-table-img {
  border-radius: 50%;
} */
